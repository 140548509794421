import { styled } from 'linaria/react';
import { theme } from '../../Theme';

export default styled('main')`
  background: white;
  flex: 1 1 auto;
  padding-bottom: 2rem;

  padding-top: 180px;

  ${theme.below.lg} {
    padding-top: 120px;
  }
`;
