import React from 'react';
import { styled } from 'linaria/react';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: 90rem;
`;

const MaxWidth = ({ className = '', children }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default MaxWidth;
