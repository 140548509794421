import React, { useEffect } from 'react';

const host = 'https://www.sportmann.no/';

const Widget = ({ type, product }) => {
  switch (type) {
    case 'small-service-reviews':
      return (
        <div
          className="lipscore-service-review-badge-small-short lipscore-no-border lipscore-no-separator"
          data-ls-widget-height="35px"
          data-ls-widget-width="200px"
        ></div>
      );

    case 'rating':
      const common = {
        id: 'lipscore-' + type,
        className: 'lipscore-widget',
        productName: product?.name,
        brand: product?.customFields?.filter(
          item => item.key === 'varumarke'
        )[0]?.stringValue,
        productId:
          product?.variants?.values?.length > 0
            ? product?.variants?.values[0]?.articleNumber
            : product?.articleNumber,
        productUrl: product?.primaryRoute?.path,
        description: product?.description,
        imageUrl:
          product?.images && product?.images?.length && product?.images[0].url,
        price: product?.price?.price,
        stockStatus: product?.stockStatus?.text
      };

      if (common.productId.length > 6) {
        common.productId = common.productId.substring(0, 6);
      }

      return (
        <div
          id="lipscore-rating"
          data-ls-product-name={common?.productName}
          data-ls-brand={common?.brand}
          data-ls-product-id={common?.productId}
          data-ls-product-url={host + common?.productUrl}
          data-ls-image-url={common?.imageUrl}
          data-ls-description={common?.description}
          data-ls-price={common?.price}
          data-ls-price-currency="SEK"
          data-ls-availability={common?.stockStatus}
        />
      );

    case 'review-list':
      const rlParams = {
        id: 'lipscore-' + type,
        className: 'lipscore-widget',
        productName: product?.name,
        brand: product?.customFields?.filter(
          item => item.key === 'varumarke'
        )[0]?.stringValue,
        productId:
          product?.variants?.values?.length > 0
            ? product?.variants.values[0].articleNumber
            : product?.articleNumber,
        productUrl: product?.primaryRoute.path,
        description: product?.description,
        imageUrl:
          product?.images && product?.images?.length && product?.images[0].url,
        price: product?.price?.incVat,
        stockStatus: product?.stockStatus?.text,
        category: product?.canonicalCategory
      };

      if (rlParams.productId.length > 6) {
        rlParams.productId = rlParams.productId.substring(0, 6);
      }

      return (
        <div
          id="lipscore-review-list"
          data-ls-product-name={rlParams?.productName}
          data-ls-brand={rlParams?.brand}
          data-ls-product-id={rlParams?.productId}
          data-ls-product-url={host + rlParams?.productUrl}
          data-ls-image-url={rlParams?.imageUrl}
          data-ls-price={rlParams?.price}
          data-ls-price-currency="SEK"
          data-ls-availability={rlParams?.stockStatus}
          data-ls-category={rlParams?.category}
          data-ls-page-size={10}
        />
      );
    default:
      return null;
  }
};

const LipscoreWidget = ({ type, product = null }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window?.lipscore) {
      window?.lipscore?.initWidgets();
    }
  });
  return <Widget type={type} product={product} />;
};

export default LipscoreWidget;
