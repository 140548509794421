import React from 'react';
import { Query } from 'react-apollo';
import { useLocation } from 'react-router';
// import { styled } from 'linaria/react';

import { Shortcodes } from '@jetshop/flight-shortcodes';
import dynamicCategoryContentQuery from './dynamicCategoryContentQuery.gql';

const StartpageText = ({ children }) => {
  const location = useLocation();
  if (location.pathname !== '/') return null;
  return children;
};

const DynamicCategoryContent = ({ id }) => (
  <Query query={dynamicCategoryContentQuery} variables={{ id: id }}>
    {({ loading, error, data }) => {
      if (loading || error) return null;
      var content = data?.category?.content;
      if ( !content ) return null;
      return (
        <React.Fragment>
          {content ? (
            <Shortcodes
              content={data.category.content}
              components={{
                StartpageText
              }}
            />
          ) : null}
        </React.Fragment>
      );
    }}
  </Query>
);

export default DynamicCategoryContent;
