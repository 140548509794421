import React from 'react';
import boot from '@jetshop/core/boot/client';
import Shop from './components/Shop';
import config from './shop.config';

boot(<Shop />, {
  ...config,
  trackers: [
    {
      name: 'SiteGainer',
      trackPageView: () => {
        if (window.sitegainer) {
          window.sitegainer.newPage();
        } else {
          document.addEventListener('sitegainer-loaded', function(e) {
            window.sitegainer.newPage();
          });
        }
      }
    }
  ]
});
