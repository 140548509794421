import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';
import Badge from '../ui/Badge';
import { styled } from 'linaria/react';

const FavoritesLink = styled('button')`
  background: none;
  border: 0;
  appearance: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  margin-left: 20px;

  svg {
    width: 23px !important;
    height: 23px !important;
    display: block;
    fill: transparent;
  }
  .badge {
    position: absolute;
    top: -3px;
    right: -7px;
  }
`;

export function FavouriteCount({ className }) {
  const { count } = useProductList();

  return (
    <DrawerTrigger preventOverflow={true} id="favourites-drawer">
      {drawer => (
        <FavoritesLink
          target={drawer}
          onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
          className={className}
        >
          {count > 0 && <Badge text={count} />}
          <HeartSVG />
        </FavoritesLink>
      )}
    </DrawerTrigger>
  );
}
