import React from 'react';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';

export const LoadableStandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableWindowedCategoryPage = loadable(
  () => import('./WindowedCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const CategoryPage = props => {
  return <LoadableWindowedCategoryPage {...props} />;
};

export default CategoryPage;
