import { useScript } from '../../hooks/useScript';

export function LipscoreAuth() {
  if (typeof window !== 'undefined') {
    window.lipscoreInit = function() {
      window.lipscore.init({
        apiKey: '8d3a328fb62f19e202e20c29'
      });
    };
  }
  useScript('//static.lipscore.com/assets/no/lipscore-v1.js');

  return null;
}
