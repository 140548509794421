import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import React, { Fragment } from 'react';
import Badge from '../ui/Badge';
import cartQuery from './CartQuery.gql';
import { ReactComponent as ShoppingBag } from '../../svg/ShoppingBag.svg';
import { theme } from '../Theme';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  margin-left: 20px;
  ${theme.below.lg} {
    margin-left: 10px;
  }
`;

const cartButtonWrapper = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  svg {
    width: 22px !important;
    height: 22px !important;
    display: block;
    margin: 0;
    use {
      fill: #000;
    }
  }

  .badge {
    position: absolute;
    top: -6px;
    right: -12px;
    text-align: center;
  }
`;

function CartButton({ target, itemsInCart, className }) {
  return (
    <div className={cartButtonWrapper}>
      <Button
        className={className}
        data-testid="cart-button"
        onClick={target.isOpen ? target.hideTarget : target.showTarget}
      >
        <ShoppingBag />
        {itemsInCart > 0 && <Badge text={itemsInCart} />}
      </Button>
    </div>
  );
}

function CartButtonFlyout({ className }) {
  return (
    <Fragment>
      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = result?.data?.cart?.totalQuantity || 0;

          return (
            <DrawerTrigger preventOverflow={true} id="cart-drawer">
              {drawer => (
                <CartButton
                  className={className}
                  target={drawer}
                  itemsInCart={itemsInCart}
                />
              )}
            </DrawerTrigger>
          );
        }}
      </CartProvider>
    </Fragment>
  );
}

export default CartButtonFlyout;
