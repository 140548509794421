import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { css, cx } from 'linaria';
import { Price } from '@jetshop/ui/Price';
import t from '@jetshop/intl';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }

  .product-card-detail {
    background: white;
    padding: 0.75em;
    line-height: 1.35;
    text-align: center;
    h3 {
      font-weight: 600;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    h4 {
      font-weight: normal;
      font-size: 14px;
      color: black;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  .price-package-wrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
    > div {
      width: 100%;
      justify-content: center;
    }
    .package-price-label {
      font-size: 14px;
      color: grey;
      margin-left: 5px;
    }
    * {
      font-size: 16px;
    }
  }

  .image-wrapper {
    position: relative;
    width: 100%;
    display: block;
    > div > div {
      position: absolute !important;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.025);
      pointer-events: none;
      z-index: 1;
    }
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '5:6',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  forwardRef,
  as = 'li',
  children,
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  let badges = [...product.badges];
  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });
  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink
        product={product}
        {...linkProps}
        style={{
          margin: '0 0.5em 1em'
        }}
      >
        <div className="image-wrapper">
          {hasImages ? (
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
              title={product.articleNumber}
            >
              <Badges badges={badges} />
            </Image>
          ) : (
            <Image src={transparentDataImg} />
          )}
        </div>

        <section className="product-card-detail">
          <header>
            <h3>{product.name}</h3>
            {/* <h4 className="sub-name">{product.subName || '\u00A0'}</h4> */}
          </header>
          <span className="price-package-wrapper">
            <Price
              price={product.price}
              previousPrice={product.previousPrice}
            />
            {product.isPackage && (
              <span className="package-price-label">{t('Package Price')}</span>
            )}
          </span>
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
