import React from 'react';
import { styled } from 'linaria/react';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';
import { theme } from '../Theme';
import { ReactComponent as ArrowRight } from '../../svg/ArrowRight.svg';

const FieldErrorWrapper = styled('div')`
  ${theme.below.lg} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  max-width: 31rem;
  margin: 0 auto;
  background: white;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex: 0 1 20rem;

  ${theme.above.md} {
    margin-bottom: 2rem;
  }
  form,
  input {
    height: 100%;
    width: 100%;
  }

  input {
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    font-size: 16px;
    background: black;
    border: 0;
    border-bottom: 1px solid white;
    color: white;
    &:focus {
      outline: none;
    }
  }
  button {
    color: white;
    background: none;
    border: 0;
    outline: none;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    height: 100%;
    cursor: pointer;
    width: 30px;
    svg {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      left: 0;
    }
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #650e1b;
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => {
          return (
            <FieldErrorWrapper>
              {this.state.error ? (
                <Error>
                  {getErrorDetail(result.error).codes.includes(
                    'AlreadySubscribed'
                  )
                    ? t('You have already subscribed to the newsletter!')
                    : t(
                        'Something went wrong. Please check your email and try again.'
                      )}
                </Error>
              ) : null}
              <Wrapper>
                {/* Show the completion success message once signed up, otherwise show the form */}
                {this.state.completed ? (
                  <p>{t('You are now subscribed')}</p>
                ) : (
                  <form onSubmit={submitEmail(this.state.email, subscribe)}>
                    <Intl>
                      {/* This is necessary to use t for the placeholder string */}
                      {t => (
                        <input
                          name="newsletter"
                          type="email"
                          placeholder={t('Enter email address')}
                          onChange={this.updateEmail}
                          style={{
                            paddingRight: result.loading ? '8rem' : '4rem'
                          }}
                        />
                      )}
                    </Intl>
                    <button>
                      <ArrowRight />
                    </button>
                  </form>
                )}
              </Wrapper>
            </FieldErrorWrapper>
          );
        }}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
