import React from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';

import t from '@jetshop/intl';
import { theme } from '../../Theme';
import CategoryLink from '@jetshop/ui/CategoryLink';

import MaxWidth from '../MaxWidth';
import NewsletterField from '../../Newsletter/NewsletterField';
import FooterQuery from './FooterQuery.gql';
import Payment1 from '../../../img/payment1.png';
import Payment2 from '../../../img/payment2.png';

const PoweredByWrapper = styled('div')`
  display: block !important;
  > p {
    display: block !important;
  }
`;

const Wrapper = styled('div')`
  background: black;
  padding: 3rem 0 2rem 0;

  p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  h2 {
    text-align: left;
    color: white;
    font-size: 20px;
    line-height: 17px;
    font-weight: normal;
    margin-bottom: 30px;
  }
`;

const NewsletterFieldWrapper = styled('div')`
  margin-bottom: 20px;
  text-align: center;
  color: white;
  margin: auto;
  width: 100%;
  p {
    text-align: center;
    color: white;
    max-width: 600px;
    margin: auto;
  }
`;

const StyledMaxWidth = styled(MaxWidth)``;

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  ${theme.below.lg} {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 10px;
  }
`;
const Column = styled('div')`
  color: white;
  margin-bottom: 2rem;
  width: 100%;
  flex: ${props => (props.flex ? props.flex : 1)};

  ${theme.below.lg} {
    width: 50%;
    max-width: 100%;
    text-align: left;
    flex: none;

    &.mobile-full {
      width: 100%;
    }

    h2,
    a,
    li,
    p {
      justify-content: left;
      text-align: left !important;
    }
  }

  a,
  a:visited {
    color: white;
    text-decoration: none;
  }

  p {
    font-size: 14px;
    padding-right: 30px;
  }

  li,
  li a {
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    display: block;
    margin-bottom: 2px;
  }

  img {
    width: 250px;
    max-width: 100%;
  }
`;

const BottomRow = styled('div')`
  text-align: center;
  color: white;
  padding: 30px 10px;
  font-size: 11px;
  p {
    font-size: 11px;
  }
  ${theme.below.lg} {
    max-width: 230px;
    margin: auto;
  }
`;

const SocialLink = styled('a')`
  margin: 0 0 2px 0;
  display: block;
`;

const SocialLinkWrapper = styled('div')`
  display: block;
`;

const Socials = () => (
  <>
    <h2>{t('Follow us')}</h2>
    <SocialLinkWrapper>
      <SocialLink href="https://www.instagram.com/sportmann.no" target="_blank">
        Instagram
      </SocialLink>
      <SocialLink href="https://www.facebook.com/sportmannweb" target="_blank">
        Facebook
      </SocialLink>
      <SocialLink
        href="https://www.youtube.com/user/SportmannTV/videos"
        target="_blank"
      >
        Youtube
      </SocialLink>
      <SocialLink href="https://www.pinterest.com/sportmann_no" target="_blank">
        Pinterest
      </SocialLink>
    </SocialLinkWrapper>
  </>
);

const Bottom = () => (
  <BottomRow>
    {t('Copyright © 2020 Sportmann.no. All rights reserved.')}

    <PoweredByWrapper>
    </PoweredByWrapper>
  </BottomRow>
);

const CategoryColumn = ({ id, title }) => (
  <Query query={FooterQuery} variables={{ id }}>
    {({ loading, error, data }) => {
      if (loading || error) return null;
      return (
        <>
          {data.category && (
            <React.Fragment>
              <h2>{title ? title : data.category.name}</h2>
              {data.category.subcategories.length > 0 ? (
                <ul>
                  {data.category.subcategories.map((subcat, i) => (
                    <li key={i}>
                      <CategoryLink category={subcat}>
                        {subcat.name}
                      </CategoryLink>
                    </li>
                  ))}
                </ul>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.category.content
                  }}
                />
              )}
            </React.Fragment>
          )}
        </>
      );
    }}
  </Query>
);

const Footer = () => (
  <>
    <Wrapper>
      <StyledMaxWidth>
        <Row>
          <Column flex="2">
            <h2>{t('Contact')}</h2>
            <p>Rosenborgveien 8, 1630 Gml. Fredrikstad</p>
            <p>
              Tel: <a href="tel:69300605">69 300 605</a> (hverdager 8-16)
              <br />
              E-post:{' '}
              <a href="mailto:kundeservice@sportmann.no">
                kundeservice@sportmann.no
              </a>
            </p>
          </Column>
          <Column flex="1">
            <CategoryColumn id={165} />
          </Column>

          <Column flex="1">
            <CategoryColumn id={166} />
          </Column>

          <Column flex="1" className="mobile-full">
            <h2>{t('Newsletter')}</h2>
            <NewsletterFieldWrapper>
              <NewsletterField />
            </NewsletterFieldWrapper>
          </Column>
        </Row>
        <Row>
          <Column flex="2" className="mobile-full">
            <CategoryColumn id={163} title="Om oss" />
          </Column>
          <Column flex="2" className="mobile-full">
            <CategoryColumn id={164} title="Butikken vår" />
          </Column>
          <Column flex="1" className="mobile-full">
            <Socials />
          </Column>
        </Row>
        <Row>
          <Column style={{ textAlign: 'center' }} className="mobile-full">
            <h2 style={{ textAlign: 'center' }}>{t('Safe payments')}</h2>
            <img src={Payment1} alt="Safe payment" />
            <img src={Payment2} alt="Safe payment" />
          </Column>
        </Row>
      </StyledMaxWidth>

      <Bottom />
    </Wrapper>
  </>
);

export default Footer;
