import { Notifications } from '../Notifications';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import MobileMenu from './MobileMenu';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import { SearchBar } from './SearchBar';
import Logo from '../../../img/logo.png';
import { ReactComponent as Bars } from '../../../svg/Bars.svg';
import { ReactComponent as User } from '../../../svg/User.svg';
import FavouritesFlyout from '../../ProductList/FavouritesFlyout';
import LipscoreWidget from '../../Lipscore/LipscoreWidget';

import { theme } from '../../Theme';
import DynamicCategoryContent from '../../ui/DynamicCategoryContent';

const MyPagesLink = styled(Link)`
  margin-left: 10px;
  svg {
    width: 20px !important;
    height: 20px !important;
    display: block;
  }
`;

const Container = styled('header')`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  &.scrolled {
    .top-bar {
      height: 0;
      overflow: hidden;
    }

    .search-bar {
      ${theme.below.lg} {
        max-width: 200px;
        transform: translateX(-8px);
        padding: 0;
        margin-top: 0;
        svg {
          left: 10px;
        }
        input {
          height: 35px;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 0 5px 0 32px;
        }
      }
    }

    > div {
      padding: 0;
    }

    [data-flight-searchfield] {
      ${theme.above.lg}  {
        max-width: 170px;
      }
    }

    .menu-container li a {
      font-size: 14px;
      padding: 1rem 0.5rem;
    }

    .center {
      visibility: hidden;
      display: none;
    }
    .right {
      position: absolute;
      right: 20px;
      top: 15px;
      z-index: 1;
      ${theme.below.lg} {
        top: 17px;
      }
    }
    .left {
      position: absolute;
      left: 20px;
      top: 8px;
      z-index: 1;
      ${theme.below.lg} {
        top: 17px;
      }
    }
  }

  a,
  a:visited {
    color: black;
  }
`;

export const MenuButton = styled('button')`
  border: 0;
  appearance: none;
  background: none;
  &:focus {
    outline: none;
  }
  svg {
    width: 20px !important;
    height: 20px !important;
    display: block;
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

const TopBarWrapper = styled('div')`
  background: #f7f7f7;
  text-align: center;
`;
const TopBarInner = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      padding: 10px;
      font-size: 12px;
      letter-spacing: 1px;
      ${theme.below.lg} {
        font-size: 10px;
        letter-spacing: 0.5px;
        padding: 5px 10px;
      }
    }
  }
`;

const HeaderWrapper = styled('div')`
  padding: 20px 0;
  ${theme.below.lg} {
    padding: 10px 0;
  }
`;
const HeaderInner = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;
const HeaderColumn = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  &.left {
    justify-content: flex-start;
    text-align: left;
  }
  &.center {
    justify-content: center;
    flex: 2;
    text-align: center;
  }
  &.right {
    justify-content: flex-end;
    text-align: right;
  }
`;
const LogoWrapper = styled('div')`
  a {
    display: block;
  }
  img {
    max-width: 100%;
  }
`;

const LipscoreWidgetContainer = styled('div')`
  display: flex;
  padding-left: 20px;
  div.lipscore-service-review-badge-small-short {
    svg text {
      fill: #333333;
    }

    .lipscore-service-review-logo {
      display: none;
    }
  }
`;

function TopBar() {
  return (
    <>
      <Above breakpoint="lg">
        <TopBarWrapper className="top-bar">
          <TopBarInner>
            <DynamicCategoryContent id={160} />
            <LipscoreWidgetContainer>
              <LipscoreWidget type="small-service-reviews" />
            </LipscoreWidgetContainer>
          </TopBarInner>
        </TopBarWrapper>
      </Above>
      <Below breakpoint="lg">
        <TopBarWrapper className="top-bar">
          <TopBarInner>
            <DynamicCategoryContent id={161} />
          </TopBarInner>
        </TopBarWrapper>
      </Below>
    </>
  );
}

function TopHeader() {
  const { routes } = useShopConfig();
  return (
    <>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <HeaderWrapper>
                <HeaderInner>
                  <HeaderColumn className="left">
                    <SearchBar />
                  </HeaderColumn>

                  <HeaderColumn className="center">
                    <LogoWrapper>
                      <Link to="/">
                        <img src={Logo} alt="Sportmann" />
                      </Link>
                    </LogoWrapper>
                  </HeaderColumn>

                  <HeaderColumn className="right">
                    <MyPagesLink to={routes.myPages.path}>
                      <User />
                    </MyPagesLink>
                    <FavouriteCount />
                    <CartButton />
                  </HeaderColumn>
                </HeaderInner>
              </HeaderWrapper>
            </>
          ) : (
            <>
              <HeaderWrapper>
                <HeaderInner>
                  <HeaderColumn className="left">
                    <DrawerTrigger preventOverflow={true} id="menu-drawer">
                      {drawer => (
                        <MenuButton
                          onClick={
                            drawer.isOpen
                              ? drawer.hideTarget
                              : drawer.showTarget
                          }
                        >
                          <IconContainer>
                            <Bars />
                          </IconContainer>
                        </MenuButton>
                      )}
                    </DrawerTrigger>
                    <MyPagesLink to={routes.myPages.path}>
                      <User />
                    </MyPagesLink>
                  </HeaderColumn>

                  <HeaderColumn className="center">
                    <LogoWrapper>
                      <Link to="/">
                        <img src={Logo} alt="Sportmann" />
                      </Link>
                    </LogoWrapper>
                  </HeaderColumn>

                  <HeaderColumn className="right">
                    <FavouriteCount />
                    <CartButton />
                  </HeaderColumn>
                </HeaderInner>
                <SearchBar />
              </HeaderWrapper>
            </>
          )
        }
      </Above>
    </>
  );
}

export default function Header() {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (window.pageYOffset >= 175) {
      setSticky(true);
    }
    if (window.pageYOffset < 175) {
      setSticky(false);
    }
  };

  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  const mobileResult = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 3
    }
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <>
      <ChannelBanner />
      <Container className={isSticky ? 'scrolled' : 'not-scrolled '} ref={ref}>
        <TopBar />
        <TopHeader categories={result} scrolled={isSticky ? true : false} />
        <Above breakpoint="lg">
          <CategoryMenu data={result.data} />
        </Above>
      </Container>
      <Below breakpoint="lg">
        <MobileMenu data={mobileResult.data} />
      </Below>
      <CartFlyout />
      <FavouritesFlyout />
      <Notifications />
    </>
  );
}
