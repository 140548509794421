import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import { LoadableProductPage, LoadableContentPage } from './components/Shop';
import {
  LoadableStandardCategoryPage,
  LoadableWindowedCategoryPage
} from './components/CategoryPage/CategoryPage';
import { theme } from './components/Theme';

const shopConfig = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'sportmann',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 }
      }
    ]
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  relewareEnabled: false,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
            currencyDisplay: 'symbol'
          },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          NKR: { style: 'currency', currency: 'NOK', minimumFractionDigits: 0 },
          AUD: { style: 'currency', currency: 'USD' }
        }
      }
    }
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false || process.env.REACT_APP_SINGLE_DOMAIN_MODE,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  trackers: [],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [LoadableProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [LoadableContentPage]
    }
  },
  usePolyfilling: true,
  deprecations: {
    /** Will be removed in 5.0.0 */
    useOldProductVariants: false,
    useOldFilterAPI: false
  }
};

export default shopConfig;
