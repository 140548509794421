import React from 'react';
import { styled } from 'linaria/react';
import { SearchField } from '@jetshop/ui/Search';
import AutocompleteQuery from './AutocompleteQuery.gql';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import { Intl } from '@jetshop/intl';

import { theme } from '../../Theme';

const InnerSearchBar = styled('div')`
  position: relative;
  margin: 0 auto;
  width: 100%;

  ${theme.below.lg} {
    padding: 0 20px;
    margin-top: 10px;
  }

  svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    right: auto;
    z-index: 1;
    width: 20px !important;
    height: 20px !important;
    ${theme.below.lg} {
      left: 30px;
    }
  }
`;

const StyledSearchField = styled(SearchField)`
  justify-content: flex-start;
  width: 100%;
  max-width: 200px;
  margin: 0;
  ${theme.below.lg} {
    max-width: 100%;
  }

  ${SearchField.Wrapper} {
    ${theme.below.lg} {
      width: 100%;
      position: relative;
    }
    input {
      font-size: 12px;
      border: 0;
      background: white;
      height: 40px;
      width: 100%;
      padding: 0 10px 0 40px;
      border-radius: 30px;
      border: 1px solid black;
      ${theme.below.lg} {
        font-size: 16px;
        border-radius: 30px;
      }
      &:focus {
        background: #eaeaea;
        outline: none;
      }
    }
  }
  ${SearchField.Cancel} {
    display: none;
  }
  ${SearchField.Flyout} {
    text-align: left;
    top: calc(100% + ((54px - 100%) / 2));
    ${theme.below.lg} {
      top: calc(100% + ((64px - 100%) / 2));
      width: calc(100% + 4rem + 16px);
      left: -8px;
      border-left: none;
      border-right: none;
    }
    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      border-top: 1px solid #e8e8e8;
    }
    a {
      padding: 0.5rem;
      display: block;
    }
  }
`;

const SearchBar = () => (
  <InnerSearchBar className="search-bar">
    <Search />
    <Intl>
      {t => (
        <StyledSearchField
          autocompleteQuery={AutocompleteQuery}
          onCancel={() => null}
          placeholder={t('Search for products')}
        />
      )}
    </Intl>
  </InnerSearchBar>
);

export { SearchBar };
